<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="true"
          title="Program List"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <div class="row">
        <div class="col-10">
          <label for="colFormLabel" class="col-form-label">Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="onClickSearchButton"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Go
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 mt-2">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>

    <ListTable
        :programs="programList"
        @onEdit="onEditProgram"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>

    <ProgramAddModal
        :modalTitle="program.id ? 'Edit Program' : 'Add Program'"
        :showParent="true"
        ref="program"
        :formData="program"
        @onSubmitForm="handleProgramSubmit"
        @onCloseProgramModal="resetForm"
    />
  </div>
</template>

<script>
import TitleButton from '@/components/atom/TitleButton'
import ListTable
  from '@/components/molecule/procurement/organization-settings/admin-setup/program/ProgramListingTable.vue'
import ProgramAddModal
  from '@/components/molecule/procurement/organization-settings/admin-setup/program/ProgramAddModal.vue'

import Pagination from '@/components/atom/Pagination'
import Loader from '@/components/atom/LoaderComponent'
import {inject} from "vue";
import handlePrograms from "@/services/modules/procurement/organization-settings/program";

export default {
  name: 'ProgramList',

  components: {
    TitleButton,
    ListTable,
    ProgramAddModal,
    Pagination,
    Loader,
  },

  data: () => ({
    loading: false,
    quick_search: null,
    offset: 20,
    programList: [],
    program: {
      name: '',
      short_name: '',
      code: null,
      description: null,
      status: ''
    },
  }),

  computed: {
    companyId() {
      return this.$route.params.companyId
    }
  },

  methods: {
    onClickAddNewButton() {
      this.$refs.program.toggleModal()
    },

    onClickSettingButton() {
      alert('Setting Button clicked');
    },

    onClickSearchButton() {
      alert('Search Button clicked');
    },

    resetForm() {
      this.program = {
        name: '',
        short_name: null,
        description: null,
        code: null,
        status: null,
      }
    },

    async handleProgramSubmit() {
      this.loading = true

      this.program.company_id = this.$route.params.companyId;
      this.program.user_id = this.$store.getters["user/getUser"].id;
      this.program.updated_by = this.$store.getters["user/getUser"].id;

      try {
        let res = null;

        if (this.program.id) {
          res = await this.updateProgram(this.program.id, this.program);
        } else {
          res = await this.createNewProgram(this.program);
        }

        if (!res.status) {
          this.showError(res.message);
        } else {
          this.showSuccess(res.message);
          this.resetForm();
          this.onClickAddNewButton();
          await this.getPrograms();
        }
      } catch (err) {
        if (!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        } else if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false;
      }
    },

    async getPrograms() {
      const query = this.getQuery();

      try {
        this.loading = true;

        let res = await this.fetchProgramList(query);

        if (!res.status) {
          this.resetPagination();
        } else {
          this.programList = res.data;
        }
      } catch (err) {
        if (err.response) {
          this.showError(err.response.message)
        }
      } finally {
        this.loading = false;
      }
    },

    getQuery() {
      let query = '?company_id=' + this.companyId
      query += '&offset=' + this.offset
      if (this.page) query += '&page=' + this.page
      return query
    },

    onPageChange(page) {
      let routeQuery = Object.assign({}, this.$route.query)
      routeQuery.page = page
      this.$router.push({path: this.$route.path, query: routeQuery})
      setTimeout(() => {
        this.getPrograms();
      }, 100);
    },

    onEditProgram(data) {
      this.program = data;
      this.onClickAddNewButton();
    },

    exportTable() {
      //todo
    },
  },

  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const {
      loading,
      fetchProgramList,
      createNewProgram,
      updateProgram,
    } = handlePrograms();

    return {
      showError,
      showSuccess,
      loading,
      fetchProgramList,
      createNewProgram,
      updateProgram,
    }
  },

  mounted() {
    this.getPrograms();
  }
}
</script>
