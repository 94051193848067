import Network from "@/services/network";
import {ref} from "vue";

export default function handlePrograms() {
    const network = new Network;
    const loading = ref(false);
    const uri = '/procurement/organization-setting/program';

    const fetchProgramList = (query) => {
        return network.api('get', uri + query);
    }

    const createNewProgram = (data) => {
        return network.api('post', uri, data);
    }

    const updateProgram = (id, data) => {
        return network.api('put', `${uri}/${id}`, data);
    }

    return {
        loading,
        fetchProgramList,
        createNewProgram,
        updateProgram,
    };
}